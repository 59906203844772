
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Products</h5>
      <div class="row mb-4 gy-2 justify-content-between">
        <div class="col-sm-12 col-md-6">
          <div class="row g-3">
            <div class="col-auto">
              <button class="btn text-white btn-primary" @click="popupModalShow = true">
                <i class="bx bx-plus align-baseline"></i> <span class=""> Add </span>
              </button>
            </div>
            <div class="col-auto">
              <b-form-select class="form-select" v-model="paginate.perPage" :options="pageOptions"></b-form-select>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-lg-4">
          <div class="text-lg-end">
            <b-form-input v-model="searchQuery" type="search" placeholder="Search model..." class="form-control"></b-form-input>
          </div>
        </div>
      </div>
      <div v-if="!isLoading" class="row">
        <div class="col-md-4 col-sm-6"
          v-for="(item, dIndex) of paginatedItems" :key="dIndex">
            <product-card :item="item">
              <template v-slot:actions>
                <router-link :to="`/products/${item.id}`" href="javascript:void(0);" class="btn me-3 btn-dark btn-sm">
                  Edit</router-link>
                <a @click="deleteItem(item.id)" href="javascript:void(0);" class="btn btn-danger btn-sm">
                  Delete</a>
              </template>
            </product-card>
        </div>
      </div>
      <is-loading v-else  />
      <div class="mb-4">
        <b-pagination class="pagination-rounded" v-model="paginate.currentPage" :limit="paginate.limit" 
        :total-rows="searchedItems.length" :per-page="paginate.perPage"></b-pagination>
      </div>
      <b-modal size="lg" v-model="popupModalShow" hide-footer centered
        title="Add product'"
        title-class="fs-6" @hide="resetData()">
        <product-form v-if="popupModalShow" @closeMe="resetData()" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import ProductForm from "@/components/forms/ProductForm.vue"
import ProductCard from "@/components/cards/ProductCard.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "product-list",
  components:{
    ProductForm,
    ProductCard,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      paginate:{
        currentPage: 1,
        perPage: 10,
        limit:3
      },
      pageOptions: [10, 25, 50, 100],
      searchQuery: '',
      columns: [
          {
              key: "id",
              label: "ID"
          },
          {
            key: "name",
            label: "Name",
          },
          
          {
            key: "price",
            label: "Price",
          },
          {
            key: "type",
            label: "Type",
          },
          {
            key: "is_active",
            label: "Active",
          },
          {
              key: "is_featured",
              label: "Featured",
          },
          "action"
      ]
    }
  },
  computed: {
    items(){
      return this.$store.state.productList.products
    },
    searchedItems(){
      return this.items.filter(item => item.name ? item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) : false )
    },
    paginatedItems(){
        return this.searchedItems.slice(
          this.paginateStart,
          this.paginateEnd
        );
    },
    paginateStart(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage;
    },
    paginateEnd(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage + this.paginate.perPage;
    },
  },
  methods: {
    fetchProducts() {
      this.$store.dispatch("productList/fetchProducts")
    },
    deleteItem(itemId) {
      this.confirmDelete({
        text: "You are about to delete a product!",
      }).then((result) => {
        if (result.value) {
          const formData = new FormData()
          formData.append('data', JSON.stringify({items: this.selected}))
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/products/${itemId}/delete`, formData)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit("productList/DELETE_PRODUCT", itemId)
            }
          })
        }
      });
    },
    resetData(){
      this.popupModalShow = false;
    },
  },
  created(){
    this.$store.dispatch("productList/fetchProducts")
      .then((response => this.isLoading = false));
    this.$store.dispatch("categoryList/fetchCategories")
  },
  mounted(){
    if(this.$route.query.create){
      this.popupModalShow = true;
    }
  }
}

</script>

